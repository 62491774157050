import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [items, setItems] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = items.filter(item => 
    item.Item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [hiddenColumns, setHiddenColumns] = useState(() => {
    const storedColumns = localStorage.getItem('hiddenColumns');
    return storedColumns ? JSON.parse(storedColumns) : [];
  });

  const [grayedImages, setGrayedImages] = useState(() => {
    const storedGrayedImages = localStorage.getItem('grayedImages');
    return storedGrayedImages ? JSON.parse(storedGrayedImages) : [];
  });

  useEffect(() => {
    const fetchItems = async () => {
      const response = await axios.get('/api/data');
      setItems(response.data);
    };
    fetchItems();
  }, []);

  const columns = [ 'Prapor', 'Therapist', 'Skier', 'Peacekeeper', 'Mechanic', 'Ragman', 'Jaeger', 'Fence', 'Hideout'];
  



useEffect(() => {
  localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumns));
  localStorage.setItem('grayedImages', JSON.stringify(grayedImages));
}, [hiddenColumns, grayedImages]);

const [itemClicks, setItemClicks] = useState(() => {
  const storedItemClicks = localStorage.getItem('itemClicks');
  return storedItemClicks ? JSON.parse(storedItemClicks) : {};
});

useEffect(() => {
  localStorage.setItem('itemClicks', JSON.stringify(itemClicks));
}, [itemClicks]);

const handleItemInteraction = (column, Item, Amount) => {
  const identifier = `${column}_${Item}`;

  // Handle Image Toggle (Grayed Images)
  if (grayedImages.includes(identifier)) {
      setGrayedImages(prev => prev.filter(image => image !== identifier));
  } else {
      setGrayedImages(prev => [...prev, identifier]);
  }

  // Handle Item Click (Item Clicks)
  const currentCount = itemClicks[identifier] || 0;
  if (currentCount + 1 > Amount) {
      setItemClicks(prev => ({
          ...prev,
          [identifier]: 0
      }));
  } else {
      setItemClicks(prev => ({
          ...prev,
          [identifier]: currentCount + 1
      }));
  }
};


useEffect(() => {
  localStorage.setItem('itemClicks', JSON.stringify(itemClicks));
}, [itemClicks]);

  const handleColumnToggle = (column) => {
    if (hiddenColumns.includes(column)) {
      setHiddenColumns(hiddenColumns.filter((hiddenColumn) => hiddenColumn !== column));
    } else {
      setHiddenColumns((prevHiddenColumns) => [...prevHiddenColumns, column]);
    }
  };

  return (
    <div className="container-fluid text-white py-3">
      <div className="container text-center mb-5">
        <h1 className="display-4 text-light">Tarkov Keeper</h1>
        <input 
  type="text" 
  className="search-box text-light"
  placeholder="Search items..." 
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
/>
      </div>
  
      <div className="container">
        <div className="row no-wrap-row horizontal-row">
        {columns.map((column) => {
    // const columnItems = items.filter((item) => item.Trader === column);
    const columnItems = filteredItems.filter((item) => item.Trader === column);
    // Sort items based on grayscale status
    columnItems.sort((a, b) => {
        const aGrayed = (itemClicks[`${column}_${a.Item}`] || 0) >= a.Amount;
        const bGrayed = (itemClicks[`${column}_${b.Item}`] || 0) >= b.Amount;
        return aGrayed && !bGrayed ? 1 : !aGrayed && bGrayed ? -1 : 0;
    });

    const halfLength = Math.ceil(columnItems.length / 2);
    const firstHalfItems = columnItems.slice(0, halfLength);
    const secondHalfItems = columnItems.slice(halfLength);
  
return (
<div key={column} className="col-sm-6 col-md-4 col-lg-2 mb-3 position-relative">
    <div className={`card text-light`}>
        <img
            className="card-img-top"
            src={`/images/${column}_Portrait.jpg`}
            alt={`${column} Portrait`}
            style={{ maxWidth: '135px', margin: '0 auto' }}
            onClick={() => handleColumnToggle(column)}
        />
                          {hiddenColumns.includes(column) && (
            <div className="trader-overlay"
            onClick={() => handleColumnToggle(column)}>
                Hidden, Click to unhide
            </div>
        )}
                  <div className="card-body p-0">
                    <h4 className="card-title text-center">{column}</h4>
                    <div className={`row ${hiddenColumns.includes(column) ? 'hidden' : ''}`}>
                      {[firstHalfItems, secondHalfItems].map((halfItems, index) => (
                        <div key={index} className="col-6">
                          {halfItems.map(({ Item, Amount, Quest, FIR, img }) => (

<div key={Item} className="mb-2">
    <div className="image-container">
        <img 
            className="card-img-bottom" 
            src={`/images/items/${img}.png`} 
            alt={Item} 
            style={(itemClicks[`${column}_${Item}`] || 0) >= Amount ? { filter: 'grayscale(100%)', opacity: '0.2' } : {}}
        />
<div className="overlay-text" title={Item} onClick={() => handleItemInteraction(column, Item, Amount)}>
    <p className="amount-text">
      {(itemClicks[`${column}_${Item}`] || 0)}/{Amount}
    </p>
</div>
    </div>
</div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
